window.__tnt || (window.__tnt = {});

__tnt.notifyPanel = (function(){
    var Panel = function (options) {
        var lib = {};

        // Private properties
        var panel,
            opened = false,
            container,
            no_items,
            error_el,
            utm,
            template,
            subscriptions = {};

        // Private methods

        // Renders all notes from local storage
        var renderNotesFromStorage = function() {
            // Before we get stored notifications, display the no items message
            no_items.style.display = 'block';

            // Render notes from local storage
            if (localStorage.getItem('tnt_notifications')) {
                var stored_notes = JSON.parse(localStorage.getItem('tnt_notifications'));
                for (var i = stored_notes.length-1; i >= 0; i--) {
                    lib.notes.unshift(stored_notes[i]);
                    renderNewNote(stored_notes[i]);
                }
            }
        };

        // Sets topic toggles to "on" state if user is subscribed
        var setupTopicToggles = function() {
            if (localStorage.getItem('tnt_notification_subscriptions')) {
                subscriptions = JSON.parse(localStorage.getItem('tnt_notification_subscriptions'));
                Object.keys(subscriptions).forEach(function(val){
                    if (subscriptions[val] == 1) {
                        if (document.getElementById('topic-' + val)) {
                            var topicInput = document.getElementById('topic-' + val);
                            topicInput.checked = true;
                            topicInput.parentNode.querySelector('.sr-only').innerText = 'Unsubscribe';
                        }
                    }
                });
            }
        };

        // Renders all controls on the page and registers click event
        var renderControls = function() {
            var controls = document.querySelectorAll('[data-control="notification"]');
            for (i = 0; i < controls.length; i++) {
                controls[i].style.display = 'block';
                controls[i].addEventListener('click', function() {
                    lib.toggle();
                });
            }
        };

        // Renders tooltips for the controls
        var renderTooltips = function() {
            var controlTips = document.querySelectorAll('[data-control="notification"][data-tooltip]');
            for (var i = 0; i < controlTips.length; i++) {
                (function(index) {
                    var tooltip_displayed = localStorage.getItem('tnt_notification_tooltip_' + controlTips[index].dataset.id);
                    if (tooltip_displayed === null || 
                        (controlTips[index].dataset.tooltipDisplayAgain && Date.now() - tooltip_displayed > parseFloat(controlTips[index].dataset.tooltipDisplayAgain) * 60 * 60 * 1000)
                    ) {
                        var control;
                        if (controlTips[index].classList.contains('stuck')) {
                            control = controlTips[index];
                        } else {
                            control = controlTips[index].querySelector('.nav li a');
                        }
                        window.setTimeout(function(){
                            var opts = {
                                trigger: 'manual',
                                placement: controlTips[index].dataset.tooltipPlacement,
                                html: true,
                                content: '<div class="notification-tooltip">' + controlTips[index].dataset.tooltip + '</div>'
                            };
                            if (controlTips[index].dataset.tooltipPlacement === 'right') {
                                opts.container = '#' + controlTips[index].id;
                            }
                            // Replace this when we migrate away from jQuery and bootstrap
                            $(control).popover(opts);
                            $(control).popover('show');
                        }, 500);

                        var close_popover = function() {
                            $(control).popover('hide');
                            document.body.removeEventListener('click', close_popover);
                        };
                        document.body.addEventListener('click', close_popover);

                        localStorage.setItem('tnt_notification_tooltip_'+controlTips[index].dataset.id, Date.now());
                    }
                })(i);
            }
        };

        // Adds a badge to controls and ring bell to signify unread messages
        var addBadgeToControls = function() {
            if (lib.isOpen() === false) {
                var controls = document.querySelectorAll('[data-control="notification"]');
                for (i = 0; i < controls.length; i++) {
                    controls[i].classList.add('ring');
                    controls[i].querySelector('.badge-dot').style.display = 'block';
                }
            }
        };

        // Removes the badge from controls and stops ringing bell to signify that all messages have been seen
        var removeBadgeFromControls = function() {
            var controls = document.querySelectorAll('[data-control="notification"]');
            for (i = 0; i < controls.length; i++) {
                controls[i].classList.remove('ring');
                controls[i].querySelector('.badge-dot').style.display = 'none';
            }
        };

        // Registers events for the panel
        var registerEvents = function() {
            // Dismiss notification popup custom event listener
            document.addEventListener('notification-popup-removed', function(e) {
                lib.addNote(e.detail.note);
                addBadgeToControls();
            });

            // Close panel button
            panel.querySelector('.notify-panel-close').addEventListener('click', function() {
                lib.toggle();
            });

            // Dismiss panel item event
            container.addEventListener('click', function(e) {
                if (e.target && e.target.closest('.notification-item-dismiss')) {
                    var el = e.target.closest('.notification-item');
                    var index = Array.prototype.indexOf.call(el.parentNode.children, el);
                    lib.removeNote(index);
                }
            });

            // Topic toggle change event
            document.getElementById('notify-panel-settings').addEventListener('change', function(e) {
                if (e.target.classList.contains('switch-input')) {
                    e.target.disabled = true;

                    error_el.style.opacity = 0;

                    if (e.target.checked) {
                        TNCMS.Messaging.subscribe(e.target.dataset.topic).then(function() {
                            subscriptions[e.target.dataset.topic] = 1;
                            localStorage.setItem('tnt_notification_subscriptions', JSON.stringify(subscriptions));
                            e.target.disabled = false;
                            e.target.checked = true;
                            e.target.parentNode.querySelector('.sr-only').innerText = 'Unsubscribe';
                        }, function(err) {
                            console.error(err);
                            e.target.disabled = false;
                            e.target.checked = false;
                            e.target.parentNode.querySelector('.sr-only').innerText = 'Subscribe';

                            error_el.innerHTML = 'Unable to subscribe to topic. Please try again later.';
                            error_el.style.display = 'block';
                            error_el.style.opacity = 1;
                        });
                    } else {
                        TNCMS.Messaging.unsubscribe(e.target.dataset.topic).then(function() {
                            subscriptions[e.target.dataset.topic] = 0;
                            localStorage.setItem('tnt_notification_subscriptions', JSON.stringify(subscriptions));
                            e.target.disabled = false;
                            e.target.checked = false;
                            e.target.parentNode.querySelector('.sr-only').innerText = 'Subscribe';
                        }, function(err) {
                            console.error(err);
                            e.target.disabled = false;
                            e.target.checked = true;
                            e.target.parentNode.querySelector('.sr-only').innerText = 'Unsubscribe';

                            error_el.innerHTML = 'Unable to unsubscribe from topic. Please try again later.';
                            error_el.style.display = 'block';
                            error_el.style.opacity = 1;
                        });
                    }
                }
            }, false);

            // Topic error event listener
            error_el.addEventListener('transitionend', function(e) {
                e.stopPropagation();
                if (e.propertyName === 'opacity') {
                    if (e.target.style.opacity === '0') {
                        e.target.style.display = 'none';
                        e.target.style.innerHTML = '';
                    }
                }
            });

            document.addEventListener('tntStickyAnchorLoaded', function(e) {
                var stickyEl = e.detail.element;
                var controls = document.querySelectorAll('[data-control="notification"]');
                for (i = 0; i < controls.length; i++) {
                    if (controls[i].classList.contains('stuck')) {
                        controls[i].style.bottom = stickyEl.offsetHeight + 'px';
                    }
                }
            });

            document.addEventListener('tntStickyAnchorClosed', function() {
                var controls = document.querySelectorAll('[data-control="notification"]');
                for (i = 0; i < controls.length; i++) {
                    if (controls[i].classList.contains('stuck')) {
                        controls[i].style.bottom = '0px';
                    }
                }
            });
        };

        // Renders a new notification
        var renderNewNote = function(noteObj) {
            var el = fillTemplate(noteObj);
            container.insertBefore(el, container.firstChild);
            if (container.children.length > 0) {
                no_items.style.display = 'none';
            }
        };

        var removeNoteAtIndex = function (index) {
            container.removeChild(container.children[index]);
            if (container.children.length === 0) {
                no_items.style.display = 'block';
            }
        };

        var storeNotes = function() {
            localStorage.setItem('tnt_notifications', JSON.stringify(lib.notes));
        };

        var templateContent = function(template) {
            if ('content' in document.createElement('template')) {
                return template.content;
            } else {
                var fragment = document.createDocumentFragment();
                var children = template.childNodes;
                for (i = 0; i < children.length; i++) {
                    fragment.appendChild(children[i].cloneNode(true));
                }
                return fragment;
            }
        };

        var fillTemplate = function(asset) {
            var clone = templateContent(template).cloneNode(true);

            // Title
            clone.querySelector('.notification-item-header').textContent = asset.title;

            // Link
            if (asset.url) {
                var anchor = clone.querySelector('.notification-item a');
                anchor.href = asset.url;
                if (utm) {
                    anchor.href += '?';
                    Object.keys(utm).forEach(function(val, i) {
                        if (i != 0) {
                            anchor.href += '&';
                        }
                        anchor.href += val + '=' + utm[val];
                    });
                }
            } else {
                var emptyAnchor = clone.querySelector('.notification-item a');
                emptyAnchor.parentNode.removeChild(emptyAnchor);
            }

            // Image
            if (asset.image) {
                clone.querySelector('.notification-item-image img').src = asset.image;
            } else {
                var emptyImage = clone.querySelector('.notification-item-image');
                emptyImage.parentNode.removeChild(emptyImage);
            }

            // Summary / Lead
            var lead = clone.querySelector('.notification-item-summary');
            var assetSummary = asset.message;
            if (assetSummary) {
                lead.innerHTML = assetSummary;
            } else {
                var emptyLead = clone.querySelector('.notification-item-summary');
                emptyLead.parentNode.removeChild(emptyLead);
            }

            return document.importNode(clone, true);
        };


        // Public properties
        lib.notes = [];

        // Public methods
        lib.init = function(options) {
            // Setup
            panel = document.getElementById(options.panel_id);
            container = panel.querySelector('.notify-panel-content-items');
            no_items = panel.querySelector('.notify-panel-content-no-items');
            error_el = panel.querySelector('.notify-panel-settings-error');
            template = panel.querySelector('template');
            utm = options.utm;

            if (!panel.classList.contains('notify-panel')) {
                panel.classList.add('notify-panel');
            }

            renderNotesFromStorage();
            setupTopicToggles();
            renderControls();
            renderTooltips();
            registerEvents();
        };

        lib.open = function() {
            panel.classList.add('notify-panel-visible');
            opened = true;
            if (container.children.length === 0) {
                // Replace this when we migrate away from jQuery and bootstrap
                $('#notification-panel .notify-panel-nav ul li:nth-child(2) a').tab('show');
            }
            removeBadgeFromControls();

            return this;
        };

        lib.close = function() {
            panel.classList.remove('notify-panel-visible');
            opened = false;

            return this;
        };

        lib.toggle = function() {
            return this.isOpen() ? this.close() : this.open();
        };

        lib.isOpen = function() {
            return opened;
        };

        lib.addNote = function(noteObj) {
            this.notes.unshift(noteObj);
            renderNewNote(noteObj);
            storeNotes();

            return this;
        };

        lib.removeNote = function(index) {
            this.notes.splice(index, 1);
            removeNoteAtIndex(index);
            storeNotes();

            return this;
        };

        // Initialize the plugin
        lib.init(options);

        // Return public APIs
        return lib;
    };

    return Panel;
})();

(function(){
    // Check if service workers and the push api is supported
    if ('serviceWorker' in navigator && 'PushManager' in window) {
        var settings = {
            panel_id: 'notification-panel',
            utm: {
                'utm_source':'BLOX Broadcast',
                'utm_campaign':'Push Notification',
                'utm_medium':'referral',
                'utm_content':'in-panel notification'
            }
        };
        if (document.readyState == 'loading') {
            document.addEventListener('DOMContentLoaded', function() {
                __tnt.notificationPanel = new __tnt.notifyPanel(settings);
            });
        } else {
            __tnt.notificationPanel = new __tnt.notifyPanel(settings);
        }
    } else {
        throw new Error('The Push API and/or Service workers are disabled or not supported');
    }
})();